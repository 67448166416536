import React from "react"
import { FaTrashAlt, FaEdit } from "react-icons/fa"

const getJobColumns = ({
  deleteButtonClickHandler,
  updateButtonClickHandler,
}) => [
  { Header: "Job Name", accessor: "jobName" },
  { Header: "Job ID", accessor: "jobId" },
  {
    Header: "Created At",
    accessor: "createdAt",
    minWidth: 30,
    width: 50,
    maxWidth: 200,
    filter: "date",
    Cell: (props) => new Date(props.value).toLocaleString(),
  },
  {
    Header: "",
    accessor: "id",
    maxWidth: "100",
    disableFilters: true,
    sortable: false,
    Cell: (props) => <div className="action-icons"></div>,
  },
]

export default getJobColumns
