const makeMenuOptions = () => {
  return [
    {
      linkText: "Tenancies",
      linkPath: "/activations/",
    },
    {
      linkText: "Devices",
      linkPath: "/activations/devices/",
    },
    {
      linkText: "Jobs",
      linkPath: "/activations/jobs/",
    },
  ]
}

export default makeMenuOptions
