import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { Typography } from "@material-ui/core"
import { ThemeProvider } from "@material-ui/core/styles"
import TelstraTheme from "../../gatsby-theme-material-ui-top-layout/theme.js"
import Layout from "../../components/layout/layout.js"
import TableCustom from "../../components/common/table-custom/index.js"
import ToastContent from "../../components/common/toast-content.js"
import "react-toastify/dist/ReactToastify.css"
import { toast, ToastContainer } from "react-toastify"
import getJobColumns from "../../components/activations/get-job-columns.js"
import ModalCustom from "../../components/common/modal-custom/index.js"
import httpReqListJobs from "../../services/activations/http-req-list-jobs"
import makeJobsDbToRuntime from "../../services/activations/make-jobs-db-to-runtime.js"
import makeMenuOptions from "../../services/activations/make-menu-options.js"
import JobDetailsModalChildren from "../../components/activations/job-details-modal-children/index.js"
import httpReqDeleteJob from "../../services/activations/http-req-delete-job.js"
import envUtil from "../../utilities/env-util"
import { getUser } from "../../utilities/userManager"
import { useDispatch } from "react-redux"
import {
  enableLoading,
  disableLoading,
} from "../../components/common/state/loadingSlice.js"

// Define the initial state for the job details modal
const makeJobDetailsModalInitialState = () => ({
  mode: "VIEW",
  modalOpen: false,
  jobName: "Create Managed Tenancy",
  jobId: "0c397dd5-a517-4eeb-8747-d954dcad8568",
  createdAt: 1_599_208_979_123,
  jobLog: "***WORKFLOW START***\nCreate Managed Tenancy\n",
})

const Jobs = () => {
  const dispatch = useDispatch()
  // Use state for jobDetailsModal
  const [jobDetailsModal, setJobDetailsModal] = useState(
    makeJobDetailsModalInitialState()
  )

  // Use state for jobsTableData
  const [jobsTableData, setJobsTableData] = useState([])

  // Set modal open - job details modal
  const setModalOpenJobDetails = (modalOpen) => {
    setJobDetailsModal({
      ...jobDetailsModal,
      modalOpen,
    })
  }

  // Update jobs table
  const refreshJobsTableData = async () => {
    try {
      dispatch(enableLoading())
      if (envUtil.getPlatform() === "pcf") getUser()
      // List all jobs
      const jobsDb = await httpReqListJobs()
      // Update the table
      setJobsTableData(makeJobsDbToRuntime(jobsDb))
    } catch (error) {
      console.log(error)
      toast.error(<ToastContent>Error - failed to retrieve jobs</ToastContent>)
    } finally {
      dispatch(disableLoading())
    }
  }

  // Table row click handler
  const tableRowClickHandler = ({ row }) => {
    setJobDetailsModal({
      mode: "VIEW",
      modalOpen: true,
      jobName: row.original.jobName,
      jobId: row.original.jobId,
      createdAt: row.original.createdAt,
      jobLog: row.original.jobLog.replace(/\\n/g, "\n"),
    })
  }

  // Delete button click handler
  const deleteButtonClickHandler = ({ evt, original }) => {
    evt.stopPropagation()
    setJobDetailsModal({
      mode: "DELETE",
      modalOpen: true,
      jobName: original.jobName,
      jobId: original.jobId,
      createdAt: original.createdAt,
      jobLog: original.jobLog.replace(/\\n/g, "\n"),
    })
  }

  // Delete confirmed handler
  const deleteJobConfirmedHandler = async (jobId) => {
    try {
      // Close the modal
      setJobDetailsModal({
        ...jobDetailsModal,
        modalOpen: false,
      })

      // Define the request body
      const reqBodyDeleteJob = {
        inputParameters: [
          {
            name: "uuid",
            value: jobId,
          },
        ],
      }

      // Execute the workflow to delete the job
      dispatch(enableLoading())
      await httpReqDeleteJob(reqBodyDeleteJob)
      // Display a success message
      toast.info(<ToastContent>Success - the job was deleted</ToastContent>)

      // Reload the table data after 1 second
      setTimeout(() => {
        refreshJobsTableData()
      }, 1000)
    } catch (error) {
      // Display an error message
      toast.error(<ToastContent>{error.message}</ToastContent>)
    } finally {
      dispatch(disableLoading())
    }
  }

  // Component did mount
  useEffect(() => {
    refreshJobsTableData()
  }, [])

  return (
    <Layout menuOptions={makeMenuOptions()}>
      <ThemeProvider theme={TelstraTheme}>
        <Helmet title="MNaaS Activations - Jobs" />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          className="toast-container"
          toastClassName="toast-info"
          bodyClassName="toast-body"
        />
        <div className="app-wrapper">
          <Typography variant="h2" gutterBottom={true}>
            MNaaS Activations
          </Typography>
          <TableCustom
            title="Jobs"
            columns={getJobColumns({
              deleteButtonClickHandler,
              updateButtonClickHandler: null,
            })}
            data={jobsTableData}
            addButtonVisible={false}
            addButtonClickHandler={null}
            addButtonData={null}
            tableRowClickHandler={tableRowClickHandler}
            initialState={{
              pageIndex: 0,
              sortBy: [
                {
                  id: "createdAt",
                  desc: true,
                },
              ],
            }}
          />
          <ModalCustom
            modalOpen={jobDetailsModal.modalOpen}
            setModalOpen={setModalOpenJobDetails}
            modalTitle="Job Details"
          >
            <JobDetailsModalChildren
              mode={jobDetailsModal.mode}
              setModalOpen={setModalOpenJobDetails}
              jobName={jobDetailsModal.jobName}
              jobId={jobDetailsModal.jobId}
              createdAt={jobDetailsModal.createdAt}
              jobLog={jobDetailsModal.jobLog}
              deleteJobConfirmedHandler={deleteJobConfirmedHandler}
            />
          </ModalCustom>
        </div>
      </ThemeProvider>
    </Layout>
  )
}

export default Jobs
