import axios from "axios"
import envUtil from "../../utilities/env-util"

// Create a TSO token
const httpReqCreateTokenTso = () =>
  new Promise((resolve, reject) => {
    axios({
      method: "POST",
      baseURL: envUtil.getBaseURL(envUtil.tenants.activationsTso),
      url: "/tsoproxy/baocdp/rest/login",
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
      data: { username: "r258196", password: "T3lstr@&^%" },
    })
      .then((response) => {
        resolve(response.headers["authentication-token"])
      })
      .catch((error) => {
        reject(error)
      })
  })

export default httpReqCreateTokenTso
