import React from "react"
import { Button } from "@material-ui/core"
import decodeHtml from "decode-html"
import InputTextCustom from "../../common/input-text-custom"

const JobDetailsModalChildren = ({
  mode,
  setModalOpen,
  jobName,
  jobId,
  createdAt,
  jobLog,
  deleteJobConfirmedHandler,
}) => {
  // Return JSX
  return (
    <div className="ags-record-modal-children">
      <form>
        <InputTextCustom
          className="input-text-read-only"
          name="jobName"
          value={jobName}
          placeholder="Job Name"
          inputTextHandler={null}
          disabled={true}
        />
        <InputTextCustom
          className="input-text-read-only"
          name="jobId"
          value={jobId}
          placeholder="Job ID"
          inputTextHandler={null}
          disabled={true}
        />
        <InputTextCustom
          className="input-text-read-only"
          name="createdAt"
          value={new Date(createdAt).toLocaleString()}
          placeholder="Created At"
          inputTextHandler={null}
          disabled={true}
        />
        <InputTextCustom
          className="input-text-read-only"
          name="jobLog"
          value={decodeHtml(jobLog)}
          placeholder="Job Log"
          inputTextHandler={null}
          disabled={true}
          multiline={true}
          rows={2}
          rowsMax={20}
        />
      </form>
      {mode === "VIEW" ? (
        <Button variant="contained" onClick={() => setModalOpen(false)}>
          Done
        </Button>
      ) : null}

      {mode === "DELETE" ? (
        <Button
          style={{ marginRight: "10px" }}
          variant="contained"
          color="primary"
          onClick={() => deleteJobConfirmedHandler(jobId)}
        >
          Delete
        </Button>
      ) : null}

      {mode === "DELETE" ? (
        <Button variant="contained" onClick={() => setModalOpen(false)}>
          Cancel
        </Button>
      ) : null}
    </div>
  )
}

export default JobDetailsModalChildren
