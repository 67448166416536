// Convert jobs from DB to runtime
const makeJobsDbToRuntime = (jobsDb) => {
  const jobs = []
  for (let i = 0; i < jobsDb.count; i++) {
    jobs.push({
      jobName: jobsDb.jobs[i].name,
      jobId: jobsDb.jobs[i].id,
      jobLog: jobsDb.jobs[i].log,
      createdAt: jobsDb.jobs[i].updatedAt,
    })
  }
  return jobs
}

module.exports = makeJobsDbToRuntime
